import React from 'react'
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import {
  Logo,
  Slider1,
  Slider2,
  Slider3,
  Arrow,
  Intro,
  No1,
  No2,
  No3,
  Fitur1,
  Fitur2,
  Fitur3,
  Fitur4,
  Fitur5,
  Fitur6,
  Fitur7,
  Fitur8,
  Fitur9,
  Countbg,
  Galeri1,
  Galeri2,
  Galeri3,
  Galeri4,
  Galeri5,
  Galeri6,
  Galeri7,
  Galeri8,
  Testimonial,
  Blog1,
  Blog2,
  Blog3,
  Down,
  App1,
  App2,
  App3,
  App4,
  App5,
  App6,
  Ss1,
  Ss2,
  Ss3,
  Ss4,
  Ss5,
  Ss6,
  Ss7,
  BannerBottom,
  Playstore,
  Appstore,
  Brand1,
  Brand2,
  Brand3,
  Brand4,
  Brand5,
  Brand6,
  Brand7,
  Brand8,
  Brand9,
  Apit,
  Siva,
  Farhan,
  Winda,
  Sukses,
  Jimmy,
  Munir,
  Res,
  Pattern,
  LogoFooter
} from '../assets'

const Home = () => {
  return (
    <div>
      {/* Start Header */}
      <header className="header clearfix" id="header">
        {/* Header Inner */}
        <div className="header-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-2 col-xs-12">
                {/*Logo*/}
                <div className="logo">
                  <a className="index.html" href="">
                    <img src={Logo} alt="true" />
                  </a>
                </div>
                {/*/End Logo*/}
                {/*Mobile Menu*/}
                <div className="mobile-menu" />
                {/*/ End Mobile Menu*/}
              </div>
              <div className="col-md-10 col-sm-10 col-xs-12">
                {/*Main Menu*/}
                <div className="main-menu">
                  {/*Cart Bar*/}
                  <ul className="cart-search-bar">
                    {/* <li><a href="https://m.seemitra.id/" target="_blank" style={{ display: `inherit` }}>LOGIN</a> / <a href="https://m.seemitra.id/" target="_blank" style={{ display: `inherit` }}>DAFTAR</a></li> */}
                    {/* <li style={{ background: `#29b6f6` }}><a href="/affiliate" target="_blank" style={{ display: `inherit`, padding: `5px 15px`, color: `#ffffff` }}>AFFILIATE</a></li> */}
                  </ul>
                  {/*/ End Cart Bar*/}
                  {/*Navigation*/}
                  <nav className="navbar">
                    <div className="collapse navbar-collapse">
                      <ul className="nav menu navbar-nav">
                        <li className="current"><a href="#header">Beranda</a></li>
                        <li><a href="#features">Features</a></li>
                        <li><a href="#produk">Produk</a></li>
                        <li><a href="#testimoni">Testimoni</a></li>
                        {/* <li><a href="#news">News</a></li> */}
                        <li><a href="#app-features">Apps</a></li>
                        <li><a href="https://m.seemitra.id/" target="_blank" >Login / Daftar</a></li>
                        <li style={{ background: `#29b6f6`, marginTop: `5px` }}><a href="/affiliate" style={{ padding: `10px 15px 10px 0px`, color: `#ffffff` }}>AFFILIATE</a></li>
                      </ul>
                    </div>
                  </nav>
                  {/*/ End Navigation*/}
                </div>
                {/*/ End Main Menu*/}
              </div>
            </div>
          </div>
        </div>
        {/*/ End Header Inner*/}
      </header>
      {/*/ End Header*/}
      {/* Start Slider */}
      <section className="sufia-slider clearfix">
        <div className="slider-main">
          {/* Slider Item */}
          {/* <div className="single-slider" style={{ backgroundImage: `url(${Slider1})`}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="welcome-text right">
                    <h1><span>Memulai Bisnis Online</span> Jadi Lebih Mudah! </h1>
                    <div className="button">
                      <a href="https://m.seemitra.id/register" target="_blank" className="btn">Daftar</a>
                    </div>
                    <a className="b-arrow" style={{ backgroundImage: `url(${Arrow})` }} href="#app-features" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*/ End Slider Item */}
          {/* Slider Item */}
          <div className="single-slider" style={{ backgroundImage: `url(${Slider2})`}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="welcome-text right">
                    <h1><span>Cari tambahan?</span>di Seemitra.id lebih mudah </h1>
                    <p>Raih jutaan rupiah bersama aplikasi reseller dengan jangkauan komunitas se-Indonesia.</p>
                    <div className="button">
                      <a href="https://m.seemitra.id" target="_blank" className="btn">Jualan Sekarang</a>
                    </div>
                    <a className="b-arrow" style={{ backgroundImage: `url(${Arrow})` }} href="#app-features" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*/ End Slider Item */}
          {/* Slider Item */}
          {/* <div className="single-slider" style={{ backgroundImage: `url(${Slider3})`}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="welcome-text right">
                    <h1><span>Aplikasi Seemitra Untuk</span>Memudahkan Reseller! </h1>
                    <div className="button">
                      <a href="https://m.seemitra.id/register" target="_blank" className="btn">Daftar</a>
                    </div>
                    <a className="b-arrow" style={{ backgroundImage: `url(${Arrow})` }} href="#app-features" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*/ End Slider Item */}
        </div>
      </section>
      {/*/ End Slider */}

      <section className="intro-homepage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=MQP4tSR_sbI" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Intro} alt />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h3>3 LANGKAH MUDAH UNTUK MEMULAI BISNIS ANDA SENDIRI</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.3s">
              {/* Single Team */}
              <div className="single-team">
                <div className="team-head">
                  <img src={No1} alt="true" />
                </div>
                <div className="member-name">
                  <h4>1. PILIH PRODUKNYA</h4>
                  <p>Seemitra.id menyediakan produk yang terbukti laris sehingga Anda lebih mudah dalam menjualnya.</p>
                </div>
              </div>
              {/*/ End Team */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.5s">
              {/* Single Team */}
              <div className="single-team">
                <div className="team-head">
                  <img src={No2} alt="true" />
                </div>
                <div className="member-name">
                  <h4>2. PROMOSIKAN</h4>
                  <p>Promosikan produk yang sudah Anda pilih di Sosial Media maupun marketplaces.</p>
                </div>
              </div>
              {/*/ End Team */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12  wow fadeInUp" data-wow-delay="0.7s">
              {/* Single Team */}
              <div className="single-team">
                <div className="team-head">
                  <img src={No3} alt="true" />
                </div>
                <div className="member-name">
                  <h4>3. DAPATKAN KEUNTUNGAN</h4>
                  <p>Raih potensi keuntungan sampai 7 juta /bulan.</p>
                </div>
              </div>
              {/*/ End Team */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Team */}

      {/* Start Features */}
      <section id="features" className="features section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Fitur Utama Seemitra</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur2} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">DASHBOARD</a></h4>
                  <p>Dapatkan sistem yang bisa memberitahukan keuntungan yang Anda dapatkan setiap harinya. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur1} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">TOOLS MARKETING</a></h4>
                  <p>Tools marketing yang memudahkan Anda dalam berjualan di sosial media maupun beriklan. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur3} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">SUPPORT PENGIRIMAN COD</a></h4>
                  <p>Tingkatkan penjualan Anda dengan pengiriman COD melalui Seemitra.id. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur4} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">LANDING PAGE GENERATOR</a></h4>
                  <p>Membuat landing page semua produk yang diminati hanya dengan beberapa tahap </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur5} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">TUTORIAL DIGITAL MARKETING</a></h4>
                  <p>Tutorial Digital Marketing yang sudah kami lakukan dalam berjualan produk di Seemitra.id. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur6} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">CUSTOMER SERVICE</a></h4>
                  <p>Kelola Costumer dibawah Anda dengan mudah dengan memberikan akses khusus di Seemitra.id. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur7} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">MANAGEMEN PRODUK</a></h4>
                  <p>Kelola sendiri produk apa yang ingin Anda jual, tambahkan diskon, edit nama campaign dll. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur8} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">PROGRAM TANTANGAN</a></h4>
                  <p>Anda akan mendapatkan program menarik dan dapatkan juga hadiahnya. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Fitur9} alt="#" />
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">AFFILIATE</a></h4>
                  <p>Dapatkan komisi untuk Anda yang mengajak teman untuk bergabung dengan Seemitra.id. </p>
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Features */}
      {/* Start Count Down */}
      <section id="countdown" className="count-down section">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-3 col-xs-6 wow fadeInUp" data-wow-delay="0.3s">
              {/* Single Count */}
              <div className="single-count">
                <i className="fa fa-check" />
                <h2 className="count">10000</h2>
                <p>Reseller</p>
              </div>
              {/*/ End Single Count */}
            </div>
            <div className="col-md-3 col-sm-3 col-xs-6 wow fadeInUp" data-wow-delay="0.5s">
              {/* Single Count */}
              <div className="single-count">
                <i className="fa fa-cube" />
                <h2 className="count">100</h2>
                <p>Jenis Produk</p>
              </div>
              {/*/ End Single Count */}
            </div>
            <div className="col-md-3 col-sm-3 col-xs-6 wow fadeInUp" data-wow-delay="0.9s">
              {/* Single Count */}
              <div className="single-count">
                <i className="fa fa-star" />
                <h2 className="count">10</h2>
                <p>Brand</p>
              </div>
              {/*/ End Single Count */}
            </div>
            <div className="col-md-3 col-sm-3 col-xs-6 wow fadeInUp" data-wow-delay="0.7s">
              {/* Single Count */}
              <div className="single-count">
                <i className="fa fa-pencil-square" />
                <h2 className="count">1000</h2>
                <p>Order Harian</p>
              </div>
              {/*/ End Single Count */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Count Down */}

      {/* Start Brand */}
      <div id="brand" className="brand section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="brand-slider">
                {/* Single Brand */}
                <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand1} width="100px" height="100px" alt="#" /></a>
                </div>
                {/*/ End Single Brand */}
                {/* Single Brand */}
                <div className="single-brand active">
                  <a href="#" target="_blank"><img src={Brand2} width="100px" height="100px" alt="#" /></a>
                </div>
                {/*/ End Single Brand */}
                {/* Single Brand */}
                {/* <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand3} width="100px" height="100px" alt="#" /></a>
                </div> */}
                {/*/ End Single Brand */}
                {/* Single Brand */}
                <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand4} width="100px" height="100px" alt="#" /></a>
                </div>
                {/*/ End Single Brand */}
                {/* Single Brand */}
                <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand5} width="100px" height="100px" alt="#" /></a>
                </div>
                {/*/ End Single Brand */}
                {/* Single Brand */}
                <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand6} width="100px" height="100px" alt="#" /></a>
                </div>
                {/*/ End Single Brand */}
                {/* Single Brand */}
                {/* <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand7} width="100px" height="100px" alt="#" /></a>
                </div> */}
                {/*/ End Single Brand */}
                {/* Single Brand */}
                <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand8} width="100px" height="100px" alt="#" /></a>
                </div>
                {/*/ End Single Brand */}
                {/* Single Brand */}
                {/* <div className="single-brand">
                  <a href="#" target="_blank"><img src={Brand9} width="100px" height="100px" alt="#" /></a>
                </div> */}
                {/*/ End Single Brand */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*/ End Brand */}
      
      {/*  Project Archive*/}
      <section id="produk" className="project archive section">
        <div className="container">

          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Produk Unggulan</h2>
              </div>
            </div>
          </div>

          <div className="row wow fadeInUp" data-wow-delay="0.4s">
            <div className="isotop-active">
              <div className="col-md-3 col-sm-4 col-xs-12 Identity Web">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri1} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.15.000</span>
                  </div>
                </div>
                {/*/ End Single Project */}
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri2} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.45.000</span>
                  </div>
                </div>
                {/*/ End Single Project */}
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri3} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.8.000</span>
                    </div>
                </div>
                {/*/ End Single Project */}
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri4} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.12.000</span>
                  </div>
                </div>
                {/*/ End Single Project */}
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri5} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.20.000</span>
                  </div>
                </div>
                {/*/ End Single Project */}
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri6} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.25.000</span>
                  </div>
                </div>
                {/*/ End Single Project */}
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri7} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.8.000</span>
                    </div>
                </div>
                {/*/ End Single Project */}
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12">
                {/* Single Project */}
                <div className="single-project">
                  <div className="project-head">
                    <img src={Galeri8} alt="#" />
                  </div>
                  <div className='product-box'>
                    Komisi <span className="price">Rp.15.000</span>
                  </div>
                </div>
                {/*/ End Single Project */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ End Project Archive */}

      {/* Start Testimonial */}
      <section id="testimoni" className="testimonial section" style={{ backgroundImage: `url(${Pattern})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=K6M3SA22sB8" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Res} alt />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=fC5TxqTH_Vg" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Jimmy} alt />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=4kdTxk3tPKA" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Sukses} alt />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=SQVXQIyP024" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Munir} alt />
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=S9VjYx6Fjoc" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Siva} alt />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=ZbW9z0-ffR4" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Farhan} alt />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="about-video">
                <div className="single-video">
                  <a href="https://www.youtube.com/watch?v=F8q8RvtroUU" className="video-play mfp-iframe">
                    <i className="fa fa-play" /></a>
                  <img src={Winda} alt />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/*/ End Testimonial */}

      {/* Start News */}
      {/* <section id="news" className="newsblog section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Artikel Terkini</h2>
                <p>Disini Anda akan mendapatkan info seputar Seemitra dan cara berjualan </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              <div className="single-news">
                <div className="news-head">
                  <img src={Blog1} alt="#" />
                  <a href="news-single.html" className="link"><i className="fa fa-link" /></a>
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">Best Marketing ways of 2017</a></h4>
                  <div className="date">Marketing / 16 Feb, 2017</div>
                  <p>Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et </p>
                  <a href="news-single.html" className="btn">Read More <span className="fa fa-angle-double-right" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.6s">
              <div className="single-news">
                <div className="news-head">
                  <img src={Blog2} alt="#" />
                  <a href="news-single.html" className="link"><i className="fa fa-link" /></a>
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">No.1 template Ever you seen</a></h4>
                  <div className="date">Marketing / 16 Feb, 2017</div>
                  <p>Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et </p>
                  <a href="news-single.html" className="btn">Read More <span className="fa fa-angle-double-right" /></a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.8s">
              <div className="single-news">
                <div className="news-head">
                  <img src={Blog3} alt="#" />
                  <a href="news-single.html" className="link"><i className="fa fa-link" /></a>
                </div>
                <div className="news-content">
                  <h4><a href="news-single.html">We Are pixel perfect Coder!</a></h4>
                  <div className="date">Marketing / 16 Feb, 2017</div>
                  <p>Lorem ipsum dolor a sit ameti, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et </p>
                  <a href="news-single.html" className="btn">Read More <span className="fa fa-angle-double-right" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/*/ End News */}

      {/* App features */}
      <section className="app-features section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Fitur Aplikasi</h2>
                <p>Beberapa fitur utama dari Apps Seemitra yang akan membantu Kamu untuk jualan dan order dari konsumen. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="row">
                <div className="col-md-12 wow fadeInLeft" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-dashboard" />
                    <h4>User Friendly</h4>
                    <p>Tampilan Dashboard yang User Friendly</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInLeft" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-shopping-cart" />
                    <h4>Halaman Belanja</h4>
                    <p>Menampilkan semua produk seemitra.</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInLeft" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-list" />
                    <h4>List Pesanan</h4>
                    <p>Merincikan seluruh pesanan Anda.</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* App Slide */}
              <div className="app-slide">
                <div className="single-slide">
                  <img src={App1} alt="#" />
                </div>
                {/* <div className="single-slide">
                  <img src={App2} alt="#" />
                </div> */}
                <div className="single-slide">
                  <img src={App3} alt="#" />
                </div>
                {/* <div className="single-slide">
                  <img src={App4} alt="#" />
                </div> */}
                <div className="single-slide">
                  <img src={App5} alt="#" />
                </div>
                {/* <div className="single-slide">
                  <img src={App6} alt="#" />
                </div> */}
              </div>
              {/*/ End App Slide */}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="row">
                <div className="col-md-12 wow fadeInRight" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-search" />
                    <h4>Detail Produk</h4>
                    <p>Menjelaskan secara detail produk.</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInRight" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-line-chart" />
                    <h4>Analisis</h4>
                    <p>Berisikan laporan penjualan Anda.</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
                <div className="col-md-12 wow fadeInRight" data-wow-delay="0.4s">
                  {/* App Single features */}
                  <div className="app-single-features">
                    <i className="fa fa-gift" />
                    <h4>Reward</h4>
                    <p>Banyak reward menarik yang bisa didapatkan.</p>
                  </div>
                  {/*/ End App Single features */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ End App features */}

      {/* Supia App Main */}
      <section id="app-features" className="supia-app-main">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.6s">
              {/* App Image */}
              <img src={Down} alt />
              {/*/ End App Image */}
            </div>
            <div className="col-md-7 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.6s">
              {/* App Text */}
              <div className="app-text">
                <h1>Download Seemitra.id Mobile Apps From<span className="typed" /></h1>
                <p>Memudahkan Pengelolaan Penjualan dalam satu genggaman. </p>
                <div className="button">
                  <a target="_blank" href="https://m.seemitra.id" className="btn"><i className="fa fa-globe" />Toko Online</a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.seemitra" className="btn primary"><i className="fa fa-play" />Google Play</a>
                </div>
              </div>
              {/*/ End App Text */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Supia App Main */}

      {/* App Screnshoot */}
      {/* <section id="screenshot" className="app-screnshoot section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Sceenshot Aplikasi</h2>
                <p>Berikut beberapa screenshot dari aplikasi seemitra. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 wow fadeInUp"> */}
              {/* Screenshot SLide */}
              {/* <div className="screenshot-slide">
                <div className="single-shoot">
                  <img src={Ss1} alt="#" />
                </div>
                <div className="single-shoot">
                  <img src={Ss2} alt="#" />
                </div>
                <div className="single-shoot">
                  <img src={Ss3} alt="#" />
                </div>
                <div className="single-shoot">
                  <img src={Ss4} alt="#" />
                </div>
                <div className="single-shoot">
                  <img src={Ss5} alt="#" />
                </div>
                <div className="single-shoot">
                  <img src={Ss6} alt="#" />
                </div>
                <div className="single-shoot">
                  <img src={Ss7} alt="#" />
                </div>
              </div> */}
              {/*/ End Screenshot SLide */}
            {/* </div>
          </div>
        </div>
      </section> */}
      {/*/ End Supia App Screnshoot */}
      {/* App Available */}
      {/* <section className="app-available section" style={{ backgroundImage: `url(${BannerBottom})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-8 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              <h1>Daptkan sekarang juga</h1>
              <p>Install dan daftar untuk memulai Bisnis Online dengan mudah</p>
              <div className="button">
                <a href="#"><img src={Playstore} alt="#" /></a>
                <a href="#"><img src={Appstore} alt="#" /></a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* End App Available */}
      {/* Footer Top */}
      <footer className="footer wow fadeInUp" id="contact">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Single Widget */}
              <div className="col-md-4 col-sm-4 col-xs-12 ">
                <div className="single-widget about">
                  <div className="footer-logo">
                    <a href="#"><img src={LogoFooter} alt="#" /></a>
                  </div>
                  <p>Seemitra.id adalah platform mitra reseller indonesia, yang bertujuan untuk membantu orang-orang dalam memulai bisnis online.</p>
                  {/* <div className="button">
                    <a href="#" className="btn primary">About Us</a>
                  </div> */}
                </div>
              </div>
              {/*/ End Single Widget */}
              {/* Single Widget */}
              <div className="col-md-5 col-sm-5 col-xs-12">
                <div className="single-widget contact">
                  <h2>Kontak Kami</h2>
                  <ul className="list">
                    <li><i className="fa fa-home" />Kantor : Seemitra Office, Jl.pelabuan 2 km.07 RT.01/RW.08 Lemburtipar, Kec. Lembursitu, Kota Sukabumi, Jawa Barat 43169</li>
                    <li><i className="fa fa-phone" />Telp / WA : 0815-6158-084 </li>
                    <li><i className="fa fa-envelope" />Email : <a href="mailto:seemitraofficial@gmail.com">seemitraofficial@gmail.com</a></li>
                  </ul>
                </div>
              </div>
              {/*/ End Single Widget */}
              {/* Single Widget */}
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="single-widget">
                  <h2>Ikuti kami di</h2>
                  <ul className="social-icon">
                    <li className="active"><a href="https://www.facebook.com/Seemitra.id" target="_blank" ><i className="fa fa-facebook" />Facebook</a></li>
                    <li className="active"><a href="https://www.tiktok.com/@seemitra.id?" target="_blank" ><i className="fa fa-tumblr" />Tiktok</a></li>
                    <li className="active"><a href="https://www.instagram.com/seemitra.id" target="_blank" ><i className="fa fa-instagram" />Instagram</a></li>
                    <li className="active"><a href="https://www.youtube.com/c/Seemitra" target="_blank" ><i className="fa fa-youtube" />Youtube</a></li>
                  </ul>
                </div>
              </div>
              {/*/ End Single Widget */}
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                {/* Copyright */}
                <p>Ⓒ Copyright Seemitra {(new Date().getFullYear())}</p>
                {/*/ End Copyright */}
              </div>
            </div>
          </div>
        </div>

        {/*/ End Footer Bottom */}
      </footer>
      {/*/ End footer Top */}
      <a href="https://api.whatsapp.com/send?phone=628156158084&text=Hallo%21%20Admin%20Seemitra." className="float" target="_blank">
        <i className="fa fa-whatsapp my-float" />
      </a>
    </div>
  )
}

export default Home
