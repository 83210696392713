import React from 'react'
import {
  Logo, 
  AffTop,
  Slide2,
  Keuntungan,
  System,
  Pattern,
  Syarat,
  Yuk,
  LogoFooter
} from '../assets'

const Affiliate = () => {
  return (
    <div>
      {/* Start Header */}
      <header className="header clearfix">
        {/* Header Inner */}
        <div className="header-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-2 col-xs-12">
                {/*Logo*/}
                <div className="logo">
                  <a className="index.html" href="https://seemitra.id">
                    <img src={Logo} alt="true" />
                  </a>
                </div>
                {/*/End Logo*/}
                {/*Mobile Menu*/}
                <div className="mobile-menu" />
                {/*/ End Mobile Menu*/}
              </div>
              <div className="col-md-10 col-sm-10 col-xs-12">
                {/*Main Menu*/}
                <div className="main-menu">
                  {/*Cart Bar*/}
                  <ul className="cart-search-bar">
                    {/* <li><a href="https://m.seemitra.id/" target="_blank" style={{ display: `inherit` }}>LOGIN</a> / <a href="https://m.seemitra.id/" target="_blank" style={{ display: `inherit` }}>DAFTAR</a></li>
                    <li className="current" style={{ background: `#29b6f6` }}><a href="/affiliate" target="_blank" style={{ display: `inherit`, padding: `5px 15px`, color: `#ffffff` }}>AFFILIATE</a></li> */}
                  </ul>
                  {/*/ End Cart Bar*/}
                  {/*Navigation*/}
                  <nav className="navbar">
                    <div className="collapse navbar-collapse">
                      <ul className="nav menu navbar-nav">
                        <li><a href="/#header">Beranda</a></li>
                        <li><a href="/#features">Features</a></li>
                        <li><a href="/#produk">Produk</a></li>
                        <li><a href="/#testimoni">Testimoni</a></li>
                        <li><a href="/#app-features">Apps</a></li>
                        <li><a href="https://m.seemitra.id/" target="_blank" >Login / Daftar</a></li>
                        <li style={{ background: `#29b6f6`, marginTop: `5px` }}><a href="/affiliate" style={{ padding: `10px 15px 10px 0px`, color: `#ffffff` }}>AFFILIATE</a></li>
                      </ul>
                    </div>
                  </nav>
                  {/*/ End Navigation*/}
                </div>
                {/*/ End Main Menu*/}
              </div>
            </div>
          </div>
        </div>
        {/*/ End Header Inner*/}
      </header>
      {/*/ End Header*/}

      <section className="supia-app-main-aff">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-6 col-xs-12 wow fadeInLeft" data-wow-delay="0.6s">
              {/* App Image */}
              <img src={AffTop} alt />
              {/*/ End App Image */}
            </div>
            <div className="col-md-7 col-sm-6 col-xs-12 wow fadeInRight" data-wow-delay="0.6s">
              {/* App Text */}
              <div className="app-text">
                <h1>Seemitra Affiliate<span className="typed2" /></h1>
                <p>Dapatkan komisi dengan bergabung ke Seemitra Affiliates Program.</p>
                <p>Seemitra Affiliates Program memberikan komisi untuk setiap kamu yang bisa mengajak atau mempromosikan seemitra.id ke orang lain untuk daftar sebagai mitra/member seemitra seller</p>
                <p>Temukan pilihan merek produk & bagikan link untuk mengajak dan dapatkan keuntungannya!</p>
                <p>Daftarkan dirimu ke Seemitra Affiliates Program sekarang! Jika belum memiliki akun Seemitra, kamu dapat membuat akun Seemitra terlebih dahulu.</p>                
              </div>
              {/*/ End App Text */}
            </div>
          </div>
        </div>
      </section>
{/* 
      Seemitra Affiliate Keutungan Besar
      Seemitra Affiliate Mudah Digunakan

      Dapatkan komisi dengan bergabung ke Seemitra Affiliates Program.
      Seemitra Affiliates Program memberikan komisi untuk setiap kamu yang bisa mengajak atau mempromosikan seemitra.id
      Ke orang lain untuk daftar sebagai mitra/member seemitra seller.
      Temukan pilihan merek produk & bagikan link untuk mengajak dan dapatkan keuntungannya!
      Daftarkan dirimu ke Seemitra Affiliates Program sekarang! Jika belum memiliki akun Seemitra, kamu dapat membuat akun Seemitra terlebih dahulu. */}

      {/* Start Features */}
      <section className="features section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Affiliate Bersama Seemitra.id</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news">
                <div className="news-head">
                  <img src={Slide2} alt="#" />
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Features */}

      {/* Start Features */}
      <section className="features section" style={{ background: `#f0f0f0`}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Keuntungan Affiliate Bersama Seemitra.id</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news-aff">
                <div className="news-head">
                  <img src={Keuntungan} alt="#" />
                </div>
              </div>
              {/*/ End Single Testimonial */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Features */}

      {/* Start Testimonial */}
      <section className="testimonial section" style={{ backgroundImage: `url(${Pattern})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-title">
                <h2>Sistem Affiliate Bersama Seemitra.id</h2>
              </div>
              <div className="about-video">
                <div className="single-video-aff">
                  <img src={System} alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ End Testimonial */}

      {/* Start Features */}
      <section className="features section" style={{ background: `#f0f0f0` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp">
              <div className="section-title">
                <h2>Syarat Affiliate Bersama Seemitra.id</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.4s">
              {/* Single News */}
              <div className="single-news-aff">
                <div className="news-head">
                  <img src={Syarat} alt="#" />
                </div>
              </div>
              {/*/ End Single Testimonial */}

              <a href="https://api.whatsapp.com/send?phone=628156158084&text=Hallo%21%20Admin%20Seemitra." className="yuk" target="_blank">
                <img src={Yuk} alt="#" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/*/ End Features */}

      {/* Footer Top */}
      <footer className="footer wow fadeInUp" id="contact">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              {/* Single Widget */}
              <div className="col-md-4 col-sm-4 col-xs-12 ">
                <div className="single-widget about">
                  <div className="footer-logo">
                    <a href="#"><img src={LogoFooter} alt="#" /></a>
                  </div>
                  <p>Seemitra.id adalah platform mitra reseller indonesia, yang bertujuan untuk membantu orang-orang dalam memulai bisnis online.</p>
                  {/* <div className="button">
                    <a href="#" className="btn primary">About Us</a>
                  </div> */}
                </div>
              </div>
              {/*/ End Single Widget */}
              {/* Single Widget */}
              <div className="col-md-5 col-sm-5 col-xs-12">
                <div className="single-widget contact">
                  <h2>Kontak Kami</h2>
                  <ul className="list">
                    <li><i className="fa fa-home" />Kantor : Ultiface Office, Karang Tengah, Kec. Gunungpuyuh, Kota Sukabumi, Jawa Barat 43121</li>
                    <li><i className="fa fa-truck" />Gudang : Jl. Pangrango, Kota Paris Kulon Kel. Cipelang Gede Kec.Gunung Puyuh, Kota Sukabumi Jawa Barat 43123</li>
                    <li><i className="fa fa-phone" />Telp / WA : 0821-1427-7600 </li>
                    <li><i className="fa fa-envelope" />Email : <a href="mailto:info@seemitra.id">info@seemitra.id</a></li>
                  </ul>
                </div>
              </div>
              {/*/ End Single Widget */}
              {/* Single Widget */}
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="single-widget">
                  <h2>Ikuti kami di</h2>
                  <ul className="social-icon">
                    <li className="active"><a href="https://www.facebook.com/Seemitra.id" target="_blank" ><i className="fa fa-facebook" />Facebook</a></li>
                    <li className="active"><a href="https://www.tiktok.com/@seemitra.id?" target="_blank" ><i className="fa fa-tumblr" />Tiktok</a></li>
                    <li className="active"><a href="https://www.instagram.com/seemitra.id" target="_blank" ><i className="fa fa-instagram" />Instagram</a></li>
                    <li className="active"><a href="https://www.youtube.com/c/Seemitra" target="_blank" ><i className="fa fa-youtube" />Youtube</a></li>
                  </ul>
                </div>
              </div>
              {/*/ End Single Widget */}
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                {/* Copyright */}
                <p>Ⓒ Copyright Seemitra {(new Date().getFullYear())}</p>
                {/*/ End Copyright */}
              </div>
            </div>
          </div>
        </div>

        {/*/ End Footer Bottom */}
      </footer>
      {/*/ End footer Top */}
      <a href="https://api.whatsapp.com/send?phone=628156158084&text=Hallo%21%20Admin%20Seemitra." className="float" target="_blank">
        <i className="fa fa-whatsapp my-float" />
      </a>
    </div>
  )
}

export default Affiliate
